export const ARCHIVED = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M14.38,21.48V17.66a3.27,3.27,0,0,1,3.28-3.28H33l1.29-2.56A3.24,3.24,0,0,1,37.18,10H52.81a3.28,3.28,0,0,1,2.94,1.82L57,14.38H72.34a3.27,3.27,0,0,1,3.28,3.28v3.83A1.65,1.65,0,0,1,74,23.13H16A1.65,1.65,0,0,1,14.38,21.48Zm56.87,7.66v44.3A6.56,6.56,0,0,1,64.69,80H25.31a6.56,6.56,0,0,1-6.56-6.56V29.14a1.65,1.65,0,0,1,1.64-1.64H69.61A1.65,1.65,0,0,1,71.25,29.14Zm-37.19,9.3a2.19,2.19,0,1,0-4.37,0V69.06a2.19,2.19,0,1,0,4.37,0Zm13.12,0a2.19,2.19,0,0,0-4.37,0V69.06a2.19,2.19,0,0,0,4.38,0Zm13.12,0a2.19,2.19,0,1,0-4.37,0V69.06a2.19,2.19,0,1,0,4.37,0Z"/></g></svg></div> };
export const ARROW_E = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><polygon points="75 45 15 10.36 15 79.64 75 45"/></g></svg></div> };
export const ARROW_N = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><polygon points="45 15 10.36 75 79.64 75 45 15"/></g></svg></div> };
export const ARROW_S = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><polygon points="45 75 79.64 15 10.36 15 45 75"/></g></svg></div> };
export const ARROW_W = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><polygon points="15 45 75 79.64 75 10.36 15 45"/></g></svg></div> };
export const CLOSE = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M56.7,45,74.26,27.45a5.52,5.52,0,0,0,0-7.8l-3.9-3.9a5.52,5.52,0,0,0-7.8,0L45,33.3,27.45,15.74a5.52,5.52,0,0,0-7.8,0l-3.9,3.9a5.52,5.52,0,0,0,0,7.8L33.3,45,15.74,62.55a5.52,5.52,0,0,0,0,7.8l3.9,3.9a5.52,5.52,0,0,0,7.8,0L45,56.7,62.55,74.26a5.52,5.52,0,0,0,7.8,0l3.9-3.9a5.52,5.52,0,0,0,0-7.8Z"/></g></svg></div> };
export const COPY = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M58.13,71.25v5.47A3.28,3.28,0,0,1,54.84,80H17.66a3.28,3.28,0,0,1-3.28-3.28V26.41a3.28,3.28,0,0,1,3.28-3.28H27.5V63.59a7.66,7.66,0,0,0,7.66,7.66Zm0-47V10h-23a3.28,3.28,0,0,0-3.28,3.28V63.59a3.28,3.28,0,0,0,3.28,3.28H72.34a3.28,3.28,0,0,0,3.28-3.28V27.5H61.41A3.29,3.29,0,0,1,58.13,24.22ZM74.66,20l-9-9a3.28,3.28,0,0,0-2.32-1H62.5V23.13H75.63V22.3A3.28,3.28,0,0,0,74.66,20Z"/></g></svg></div> };
export const CUT = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M53.45,45l26-26a1.87,1.87,0,0,0,0-2.65,13.13,13.13,0,0,0-18.56,0l-18,18.05L39,30.51a15,15,0,1,0-11.87,9.35L32.23,45l-5.15,5.15A15,15,0,1,0,39,59.49l3.88-3.88,18,18.05a13.13,13.13,0,0,0,18.56,0,1.87,1.87,0,0,0,0-2.65ZM25,30a5,5,0,1,1,5-5A5,5,0,0,1,25,30Zm0,40a5,5,0,1,1,5-5A5,5,0,0,1,25,70Z"/></g></svg></div> };
export const ETSY = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M67.5,55.78c-.21,1.26-1.61,12.89-1.82,15.47-13.81-.5-25.77-.56-43.18,0v-3c5.33-1,7.1-.94,7.15-4.13.21-8.48.41-28.61,0-37.73-.12-3.34-1.42-3.14-7.15-4.22v-3c8.66.28,30,1,42.54-.44-.41,4.48-.91,14.82-.91,14.82H61.41C60.11,28.55,59.21,23,55,23H38.94c-1.2,0-1.26.41-1.26,1.14V43.3C44.47,43.36,48,43,48,43c3.49-.11,3.23-1,4.78-7.65h3c-.52,11.88-.46,7.25-.21,18.78h-3C51.54,49.44,51.55,47,48,46.93c0,0-2.52-.23-10.31-.23V63c0,3,1.67,4.48,5.19,4.48H53.32c7.46,0,7.8-2.93,11.57-11.69H67.5Z"/><path d="M82,82.44H8V7.56H82Zm-70-4H78V11.56H12Z"/></g></svg></div> };
export const INSTAGRAM = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M45,59A13.72,13.72,0,1,0,31.23,45.32,13.74,13.74,0,0,0,45,59Zm0-23.87A10.15,10.15,0,1,1,34.85,45.32,10.15,10.15,0,0,1,45,35.17Zm15.23,1.08H80v30A13.77,13.77,0,0,1,66.23,80H24A13.93,13.93,0,0,1,10,66.23v-30H29.68a17.64,17.64,0,0,0-2.54,9.07,17.82,17.82,0,0,0,35.64,0A17.64,17.64,0,0,0,60.23,36.25Zm6-26.25H30.42V24.58H27.5V10H24.58V24.58H21.67V10.22a14,14,0,0,0-2.92.79V24.58H15.83v-12A13.7,13.7,0,0,0,10,23.77v9.56H31.8a17.75,17.75,0,0,1,26.31,0H80V23.77A13.77,13.77,0,0,0,66.23,10Zm7.94,15.36A2.16,2.16,0,0,1,72,27.5H64.67a2.16,2.16,0,0,1-2.17-2.14V18a2.16,2.16,0,0,1,2.17-2.14H72A2.16,2.16,0,0,1,74.17,18Z"/></g></svg></div> };
export const LINK = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M54.65,35.35a20.77,20.77,0,0,1,0,29.34l0,.05-9.19,9.19A20.78,20.78,0,0,1,16.08,44.53l5.07-5.07a2.19,2.19,0,0,1,3.73,1.45,25.19,25.19,0,0,0,1.32,7.21,2.2,2.2,0,0,1-.52,2.27L23.9,52.18a9.84,9.84,0,1,0,13.83,14L46.92,57a9.84,9.84,0,0,0,0-13.92,10.24,10.24,0,0,0-1.41-1.17,2.19,2.19,0,0,1-.95-1.72,5.45,5.45,0,0,1,1.6-4.08L49,33.23A2.2,2.2,0,0,1,51.85,33a20.85,20.85,0,0,1,2.81,2.35ZM73.92,16.08a20.8,20.8,0,0,0-29.39,0l-9.19,9.19,0,.05A20.79,20.79,0,0,0,38.15,57,2.2,2.2,0,0,0,41,56.77l2.88-2.88a5.45,5.45,0,0,0,1.6-4.08,2.19,2.19,0,0,0-.95-1.72,10.24,10.24,0,0,1-1.41-1.17,9.84,9.84,0,0,1,0-13.92l9.19-9.19a9.84,9.84,0,1,1,13.83,14l-1.79,1.79a2.2,2.2,0,0,0-.52,2.27,25.19,25.19,0,0,1,1.32,7.21,2.19,2.19,0,0,0,3.73,1.45l5.07-5.07a20.8,20.8,0,0,0,0-29.39Z"/></g></svg></div> };
export const MINUS = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M73.1,53.28a5.52,5.52,0,0,0,5.52-5.52V42.24a5.52,5.52,0,0,0-5.52-5.52H16.9a5.52,5.52,0,0,0-5.52,5.52v5.52a5.52,5.52,0,0,0,5.52,5.52Z"/></g></svg></div> };
export const OPEN = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M27.54,51.74H69.65V46H52.89V39.42H42.95L34.73,31.2H14.19A6.16,6.16,0,0,0,8,37.37V73.06l8.87-15.2A12.37,12.37,0,0,1,27.54,51.74Z"/><path d="M78.89,55.85H27.54a8.22,8.22,0,0,0-7.1,4.08l-9.3,15.94a3.08,3.08,0,0,0,2.66,4.63H65.15a8.22,8.22,0,0,0,7.1-4.08l9.3-15.94A3.08,3.08,0,0,0,78.89,55.85Z"/><path d="M79.29,26.88,63.71,11.31a2.74,2.74,0,0,0-3.89,0L44.24,26.9a2.74,2.74,0,0,0,0,3.88l2.6,2.59a2.74,2.74,0,0,0,3.88,0l6.11-6.11V42h9.86V27.24l6.12,6.11a2.74,2.74,0,0,0,3.88,0l2.59-2.59A2.74,2.74,0,0,0,79.29,26.88Z"/></g></svg></div> };
export const PASTE = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M31.88,35.16a7.66,7.66,0,0,1,7.66-7.66H58.13V17.66a3.28,3.28,0,0,0-3.28-3.28h-11a8.74,8.74,0,0,0-15.15,0h-11a3.28,3.28,0,0,0-3.28,3.28V63.59a3.28,3.28,0,0,0,3.28,3.28H31.88Zm4.38-19.69A3.28,3.28,0,1,1,33,18.75,3.28,3.28,0,0,1,36.25,15.47ZM61.41,49.38H75.63V76.72A3.28,3.28,0,0,1,72.34,80H39.53a3.28,3.28,0,0,1-3.28-3.28V35.16a3.28,3.28,0,0,1,3.28-3.28H58.13V46.09A3.29,3.29,0,0,0,61.41,49.38Zm14.22-5.2V45H62.5V31.88h.83a3.28,3.28,0,0,1,2.32,1l9,9A3.28,3.28,0,0,1,75.63,44.17Z"/></g></svg></div> };
export const PLUS = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M53.28,36.72V16.9a5.52,5.52,0,0,0-5.52-5.52H42.24a5.52,5.52,0,0,0-5.52,5.52V36.72H16.9a5.52,5.52,0,0,0-5.52,5.52v5.52a5.52,5.52,0,0,0,5.52,5.52H36.72V73.1a5.52,5.52,0,0,0,5.52,5.52h5.52a5.52,5.52,0,0,0,5.52-5.52V53.28H73.1a5.52,5.52,0,0,0,5.52-5.52V42.24a5.52,5.52,0,0,0-5.52-5.52Z"/></g></svg></div> };
export const PRIVATE = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M44.84,61.53l4,5.66q-1.89.18-3.8.18A39.61,39.61,0,0,1,10.81,48a5.83,5.83,0,0,1,0-5.92A39.3,39.3,0,0,1,22.48,29.58l6.94,9.91a16.54,16.54,0,0,0,15.43,22ZM79.19,48A39.41,39.41,0,0,1,62.76,63.21h0l5,7.19a2.92,2.92,0,0,1-.72,4.06L65.5,75.58a2.92,2.92,0,0,1-4.06-.72L22.19,19.59a2.92,2.92,0,0,1,.72-4.06l1.59-1.12a2.92,2.92,0,0,1,4.06.72L34.77,24A40.11,40.11,0,0,1,45,22.64,39.61,39.61,0,0,1,79.19,42a5.83,5.83,0,0,1,0,5.92ZM61.53,45A16.54,16.54,0,0,0,38.84,29.66l2.35,3.29a12.66,12.66,0,0,1,9.77.9h0a5.2,5.2,0,1,0,5.2,5.2h0a12.64,12.64,0,0,1-1,13.48h0l2.35,3.29A16.46,16.46,0,0,0,61.53,45ZM41.84,57.24,32.42,43.78A12.64,12.64,0,0,0,41.84,57.24Z"/></g></svg></div> };
export const PUBLIC = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M79.19,42a39.83,39.83,0,0,0-68.38,0,5.83,5.83,0,0,0,0,5.92,39.83,39.83,0,0,0,68.38,0,5.83,5.83,0,0,0,0-5.92ZM45,61.53A16.53,16.53,0,1,1,61.53,45,16.52,16.52,0,0,1,45,61.53ZM57.64,45a12.64,12.64,0,1,1-23.79-6h0a5.2,5.2,0,1,0,5.2-5.2h0A12.64,12.64,0,0,1,57.64,45Z"/></g></svg></div> };
export const SAVE = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M77.8,25.3,64.7,12.2a7.5,7.5,0,0,0-5.3-2.2H17.5A7.5,7.5,0,0,0,10,17.5v55A7.5,7.5,0,0,0,17.5,80h55A7.5,7.5,0,0,0,80,72.5V30.61a7.5,7.5,0,0,0-2.2-5.3ZM45,70A10,10,0,1,1,55,60,10,10,0,0,1,45,70ZM60,22.42V38.13A1.88,1.88,0,0,1,58.13,40H21.88A1.88,1.88,0,0,1,20,38.13V21.88A1.88,1.88,0,0,1,21.88,20H57.58a1.87,1.87,0,0,1,1.33.55l.54.54A1.87,1.87,0,0,1,60,22.42Z"/></g></svg></div> };
export const TWITCH = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M14.63,8l-5,12.7V72.61H27.33V82h9.94l9.39-9.39H61L80.34,53.28V8ZM73.72,50l-11,11H45L35.61,70.4V61H20.7V14.63h53Zm-11-22.64V46.64H56V27.33ZM45,27.33V46.64H38.37V27.33Z"/></g></svg></div> };
export const TWITTER = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M82,22.05a30.31,30.31,0,0,1-8.72,2.39A15.21,15.21,0,0,0,80,16a30.41,30.41,0,0,1-9.64,3.68A15.19,15.19,0,0,0,44.44,33.57,43.1,43.1,0,0,1,13.15,17.71,15.2,15.2,0,0,0,17.85,38,15.12,15.12,0,0,1,11,36.08,15.19,15.19,0,0,0,23.15,51.16a15.22,15.22,0,0,1-6.86.26A15.19,15.19,0,0,0,30.48,62,30.53,30.53,0,0,1,8,68.25a43,43,0,0,0,23.27,6.82c28.19,0,44.11-23.81,43.15-45.16A30.91,30.91,0,0,0,82,22.05Z"/></g></svg></div> };
export const TRASH = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M14.38,21.48V17.66a3.27,3.27,0,0,1,3.28-3.28H33l1.29-2.56A3.24,3.24,0,0,1,37.18,10H52.81a3.28,3.28,0,0,1,2.94,1.82L57,14.38H72.34a3.27,3.27,0,0,1,3.28,3.28v3.83A1.65,1.65,0,0,1,74,23.13H16A1.65,1.65,0,0,1,14.38,21.48Zm56.87,7.66v44.3A6.56,6.56,0,0,1,64.69,80H25.31a6.56,6.56,0,0,1-6.56-6.56V29.14a1.65,1.65,0,0,1,1.64-1.64H69.61A1.65,1.65,0,0,1,71.25,29.14Zm-37.19,9.3a2.19,2.19,0,1,0-4.37,0V69.06a2.19,2.19,0,1,0,4.37,0Zm13.12,0a2.19,2.19,0,0,0-4.37,0V69.06a2.19,2.19,0,0,0,4.38,0Zm13.12,0a2.19,2.19,0,1,0-4.37,0V69.06a2.19,2.19,0,1,0,4.37,0Z"/></g></svg></div> };
export const WARNING = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><path d="M54,14.69H36l-26,45,9,15.64H71l9-15.64ZM41.11,25h7.78V48.31H41.11ZM45,65A5.06,5.06,0,1,1,50.06,60,5.06,5.06,0,0,1,45,65Z"/></g></svg></div> };
export const DOT = ({ className, tooltip }) => { return <div className={`icon ${className ?? ""}`} title={tooltip}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><g><circle cx="45" cy="45" r="27"/></g></svg></div> };


const output = {
    ARCHIVED,
    ARROW_E,
    ARROW_N,
    ARROW_S,
    ARROW_W,
    CLOSE,
    COPY,
    CUT,
    ETSY,
    INSTAGRAM,
    LINK,
    MINUS,
    OPEN,
    PASTE,
    PLUS,
    PRIVATE,
    PUBLIC,
    SAVE,
    TWITCH,
    TWITTER,
    TRASH,
    WARNING,
    DOT
}
export default output;
